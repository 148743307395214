import React, { useState } from "react";
import SinglePagePDFViewer from "./components/pdf/single-page";
import ReCAPTCHA from "react-google-recaptcha";

const axios = require('axios');

export default function App() {
  const [sampleBase64pdf, setSampleBase64pdf] = useState('')
  const [loading, setLoading] = useState('')
  const SiteKey = '6LezvdkaAAAAAEXbxTc5qAi2aGVmdGXQb2FxXRkc'
  // const SiteKey = '6LczW2EUAAAAAAB0-1IlaiNVtvKry7pjMy7HpIX6'

  const verifyCallback = (recaptchaToken) => {
    setLoading('Loading ...')
    // Here you will get the final recaptchaToken!!!  
    console.log('token:' + recaptchaToken)
    var url = new URL(window.location.href);
    var id = url.searchParams.get("id");
    if (id) {
      axios.post(APIURL + '/getSignedUrl', {
        name: id,
        captcha: recaptchaToken
      })
        .then(function (response) {
          if (response.data.status === 1)
            setSampleBase64pdf(response.data.link)
          else
            setLoading(response.data.message)
        })
        .catch(function (error) {
          setLoading(error.message)
        });
    }
    else {
      setLoading("Invalid Link")
    }
  }
  // useEffect(() => {
  //   loadReCaptcha(SiteKey);
  // }, []);


  const APIURL = 'https://us-central1-telemedicine-2e69d.cloudfunctions.net/api'
  // const APIURL = 'http://localhost:3002'
  return (
    <div className="App">
      {sampleBase64pdf ?
        <SinglePagePDFViewer pdf={sampleBase64pdf} />
        :
        <p>{loading}</p>
      }
      {loading === '' &&
      <ReCAPTCHA
        sitekey={SiteKey}
        onChange={verifyCallback}
      />
    }

      {/* <ReCaptcha
        ref={recaptcha}
        sitekey={SiteKey}
        verifyCallback={verifyCallback}
      /> */}
    </div>
  );
}
